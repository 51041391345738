const baseColumn = vm => {
  return [
    {
      keyId: 1,
      title: '创建时间',
      field: 'created',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}
// 操作按钮配置项
const operateList = vm => {
  return []
}

// 搜索字段配置项
const searchFields = vm => {
  return [
    {
      keyId: 11,
      title: '车牌号',
      field: 'carNumber',
      width: 160,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      selectOptions: [{
        value:'',
        label:'全部线路'
      }],
      renderType: 'status',
      copy: true, //是否拥有复制功能
      columnType: null, // 筛选自定义
      filterable: true // 是否可筛选
    },
    {
      keyId: 11,
      title: '车辆所有人',
      field: 'carOwner',
      width: 160,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      selectOptions: [{
        value:'',
        label:'全部线路'
      }],
      renderType: 'status',
      copy: true, //是否拥有复制功能
      columnType: null, // 筛选自定义
      filterable: true // 是否可筛选
    }
  ]
}

// 表格列配置
const tableFields = vm => {
  return [
    {
      "keyId": 11,
      "title": "车牌号",
      "field": "carNumber",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 12,
      "title": "车牌颜色",
      "field": "carNumberColor",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 13,
      "title": "车型",
      "field": "carModel",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 14,
      "title": "车长",
      "field": "carLength",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 15,
      "title": "车架号",
      "field": "vin",
      "width": 200,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 16,
      "title": "能源类型",
      "field": "energyType",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 17,
      "title": "车辆所有人",
      "field": "carOwner",
      "width": 200,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 18,
      "title": "使用性质",
      "field": "useProperty",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 19,
      "title": "发证机关",
      "field": "institution",
      "width": 200,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "keyId": 20,
      "title": "注册日期",
      "field": "registerDate",
      "width": 150,
      "fieldType": null,
      "searchType": "date",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": "date",
      "copy": true
    },
    {
      "keyId": 21,
      "title": "发证日期",
      "field": "issueDate",
      "width": 150,
      "fieldType": null,
      "searchType": "date",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": "date",
      "copy": true
    },
    {
      "keyId": 22,
      "title": "行驶证正页",
      "field": "drivingLicenseUp",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": "image",
      "copy": true
    },
    {
      "title": "行驶证副页",
      "field": "drivingLicenseDown",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": "image",
      "copy": true
    },
    {
      "title": "总质量",
      "field": "maxWeight",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "title": "核定载质量",
      "field": "loadWeight",
      "width": 100,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "title": "道路运输许可证号",
      "field": "roadTransport",
      "width": 200,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "title": "道路运输许可证照片",
      "field": "roadTransportImage",
      "width": 200,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": "image",
      "copy": true
    },
    {
      "title": "道路运输证有效期",
      "field": "roadTransportTime",
      "width": 200,
      "fieldType": null,
      "searchType": "date",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": "date",
      "copy": true
    },
    {
      "title": "道路运输经营许可证号",
      "field": "roadLicense",
      "width": 200,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": null,
      "copy": true
    },
    {
      "title": "道路运输经营许可证照片",
      "field": "roadLicenseImage",
      "width": 200,
      "fieldType": null,
      "searchType": "input",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": "image",
      "copy": true
    },
    {
      "title": "经营许可证有效期",
      "field": "roadLicenseTime",
      "width": 200,
      "fieldType": null,
      "searchType": "date",
      "searchValue": "",
      "isSearchShow": true,
      "selectOptions": null,
      "showField": true,
      "enum": false,
      "fieldEnumValueList": null,
      "fieldOperateTag": null,
      "sortable": false,
      "multiField": false,
      "fieldCanClick": false,
      "filterable": false,
      "textColor": null,
      "fixed": null,
      "renderType": "date",
      "copy": true
    }]
}

export default {
  operateList,
  searchFields,
  tableFields,
  baseColumn
}
